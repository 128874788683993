<template>
  <div class="customer-service">
    <!--微信客服-->
    <div class="customer-service__box" @click="handleQrCodeShow">
      <img ref="qr" v-show="isQrCodeShow" class="qr-code" src="../../assets/img/common/wechat_kefu.jpg" alt="">
      <img src="../../assets/img/common/icon_wechat@2x.png" alt="">
      <p>微信客服</p>
    </div>
    <!--qq客服-->
    <a class="customer-service__box" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=3083772610&site=qq&menu=yes">
      <img src="../../assets/img/common/icon_qq@2x.png" alt="">
      <p>qq客服</p>
    </a>
  </div>

</template>

<script>
  export default {
    name: "CustomerService",
    data() {
      return {
        isQrCodeShow: false
      }
    },
    methods: {
      handleQrCodeShow() {
        if(!this.isQrCodeShow) {
          this.isQrCodeShow = true
        } else {
          this.$refs.qr.classList.add('qr-out')
          setTimeout(() => {
            this.isQrCodeShow = false
            this.$refs.qr.classList.remove('qr-out')
          }, 500)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .customer-service {
    position: fixed;
    width: 80px;
    right: 10px;
    top: 70%;
    z-index: 10;
  }

  .customer-service__box {
    display: block;
    width: 80px;
    height: 80px;
    box-shadow: 0 0 3px 3px #b2d2ed88;
    background: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    &::before {
      content: '';
      display: table;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #333333;
    }
    img {
      display: block;
      margin: 10px auto 0;
    }
  }
  .qr-code {
    width: 100px;
    height: 100px;
    position: absolute;
    left: -110px;
    top: 0;
    margin: 0!important;
    box-shadow: 0 0 3px 3px #b2d2ed88;
    animation: qr-in 0.5s;
  }
  @keyframes qr-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes qr-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .qr-out {
    animation: qr-out 0.5s;
  }
</style>
