<template>
  <div id="app">
    <!--pc端的头部-->
    <index-header v-if="!isMobileRouter"/>
    <!--移动端的头部-->
    <m-header v-if="isMobileRouter"/>
    <!--qq客服-->
    <customer-service v-if="!isMobileRouter"/>

    <router-view class="main" :class="{mobile: isMobileRouter}"/>

    <!--pc端的尾部-->
    <index-footer v-if="!isMobileRouter"/>
  </div>
</template>

<script>
  import IndexHeader from '@/views/header/IndexHeader'
  import IndexFooter from "@/views/footer/IndexFooter";
  import MHeader from "@/m-views/m-header/MHeader";
  import CustomerService from "@/components/common/CustomerService"

  export default {
    // 手机端和pc端的框架
    name: 'layout',
    components: {
      IndexHeader,
      IndexFooter,
      MHeader,
      CustomerService
    },
    computed: {
      // 是否处于手机端的路由中
      isMobileRouter() {
        return this.$route.path.match(/^\/m/)
      }
    },
    created() {
      // 判断是否手机端
      let mobileAgent = ["iphone", "ipod", "ipad", "android", "mobile", "blackberry", "webos", "incognito", "webmate", "bada", "nokia", "lg", "ucweb", "skyfire"];
      let browser = navigator.userAgent.toLowerCase();
      let isMobile = false
      for (let i = 0; i < mobileAgent.length; i++) {
        if (browser.indexOf(mobileAgent[i]) !== -1) {
          isMobile = true
          // console.log('m')
          break
        }
      }

      // 开发环境下的跳转
      if (process.env.NODE_ENV === 'development') {
        if (isMobile && !/^\/m/.test(location.pathname)) {
          // 是移动端却在pc端首页时,跳转移动端首页
          location.replace('/m')
        } else if (!isMobile && /^\/m/.test(location.pathname)) {
          // 不是移动端却在移动端首页时,跳转pc端首页
          location.replace('/')
        }
      }

      // 预渲染时不跳转
      if (window.__INJECT__ === 'prerender') return

      // 生产环境中, 手机端跳转到m子域名
      if (process.env.NODE_ENV === 'production') {
        if (isMobile && location.host === 'fqchildren.com') {
          // 是移动端却在pc端首页时,跳转移动端首页
          location.replace('http://m.fqchildren.com/m')
        } else if (!isMobile && location.host === 'm.fqchildren.com') {
          // 不是移动端却在移动端首页时,跳转pc端首页
          location.replace('http://fqchildren.com')
        }
      }
    }
  }
</script>

<style lang="scss">
  .main {
    margin-top: 74px;
    min-width: 1750px;
  }

  .footer,
  .header {
    min-width: 1750px;
  }

  // 手机端样式
  .mobile {
    margin: 0;
    min-width: auto;
  }

</style>
