<template>
  <header class="m-header" v-if="shouldShow">
    <div class="fixed">
      <!--返回-->
      <div class="back-holder" @click="back" v-if="!isIndex">
        <div class="back"></div>
      </div>

      <!--标题-->
      <div class="title">
        <h1>{{title}}</h1>
      </div>
    </div>
    <!--撑开-->
    <div class="void"></div>
  </header>
</template>

<script>
  export default {
    name: "MHeader",
    data() {
      return {
        title: '财商少年 FQChildren'
      }
    },
    methods: {
      back() {
        history.back()
      }
    },
    computed: {
      isIndex() {
        let path = location.pathname
        return /^\/m\/?$/.test(path)
      },
      shouldShow() {
        let shouldShowPaths = [
          '/m/extra',
          '/m/category',
          '/m/tmp'
        ]
        for(let path of shouldShowPaths) {
          let re = new RegExp(`^${path}`)
          if(re.test(this.$route.path)) {
            return false
          }
        }
        return true
      }
    },
    created() {
      // console.log(this.shouldShow)
      // 在正式环境中pathname以/结尾, 在测试环境中没有/结尾, 因此要用正则
      let path = location.pathname
      if (/\/m\/competition/.test(path)) {
        this.title = '全国大赛'
      } else if (/\/m\/college/.test(path)) {
        this.title = '财商学院'
      } else if (/\/m\/curriculum/.test(path)) {
        this.title = '财商少年课程系列'
      } else if (/\/m\/grow/.test(path)) {
        this.title = '财商少年成长历程'
      }
    }
  }
</script>

<style lang="scss" scoped>
  // header高度为50px注意!

  .m-header {
    // 主体
    .fixed {
      /*box-shadow: 0 2px 2px #dddddd;*/
      height: 50px;
      width: 100%;
      position: fixed;
      background: #ffffff;
      z-index: 99;
    }

    // 空的div, 用于撑开fixed的空位
    .void {
      height: 50px;
      width: 100%;
    }
  }

  .title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    h1 {
      font-size: 18px;
      line-height: 50px;
    }
  }

  .back-holder {
    position: relative;
    width: 50px;
    height: 50px;
    /*background: #eeeeee;*/
  }

  .back {
    border-top: 2px solid #333333;
    border-left: 2px solid #333333;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }

</style>
