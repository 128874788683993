<template>
  <div class="footer">
    <img src="~@/assets/img/main/logo@2x.png" alt="" class="logo-left">

    <!--三个二维码-->
    <div class="marks clearfix">
      <div class="mark">
        <img src="~@/assets/img/main/app@2x.png" alt="中国财商少年APP" title="中国财商少年APP">
        <p>中国财商少年APP</p>
      </div>

      <div class="mark">
        <img src="~@/assets/img/main/qrcode-community.jpg" alt="财商社区小程序" title="财商社区小程序">
        <p>财商社区小程序</p>
      </div>

      <div class="mark">
        <img src="~@/assets/img/main/wx_official@2x.png" alt="中国财商少年微信公众号" title="中国财商少年微信公众号">
        <p>财商少年公众号</p>
      </div>
    </div>


    <div class="contact">
      <p>联系热线</p>
      <p>400-188-3633</p>
      <a href="https://weibo.com/u/7251140303" target="_blank">
        <img src="~@/assets/img/main/icon_weibo@2x.png" alt="新浪微博" title="中国财商少年微博" class="weibo">
      </a>
    </div>

    <div class="copy-right">

      <p><a href="https://beian.miit.gov.cn" target="_blank">Copyright © 2020 财商少年 粤ICP备19109204号-1 fqchildren.com</a></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "IndexFooter",
  }
</script>

<style lang="scss" scoped>
  .footer {
    /*min-width: 1750px;*/
    height: 300px;
    background: #353535;
    position: relative;
  }

  .logo-left {
    position: absolute;
    left: 10.5%;
    top: 50%;
    transform: translateY(-50%);
  }

  .marks {
    position: absolute;
    left: 35%;
    top: 50px;
  }

  .mark {
    float: left;
    color: #fff;
    text-align: center;
    font-size: 18px;
    margin-right: 80px;

    img {
      border: 3px solid #005dae;
      width: 150px;
      &:hover {
        outline: 2px solid #00a2e8;
      }
    }

    p:first-child {
      margin-top: 18px;
    }
  }


  .contact {
    position: absolute;
    right: 10.5%;
    top: 64px;
    color: #ffffff;
    font-size: 34px;
    line-height: 34px;

    p {
      margin-bottom: 20px;
    }
  }

  .weibo {
    width: 50px;
    transition: width 0.5s;

    &:hover {
      width: 70px;
    }
  }

  // 版权信息
  .copy-right {
    font-family: Arial sans-serif;
    color: #ffffff;
    opacity: 0.6;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
  }
</style>
