<template>
  <header class="header">
    <a href="/" class="logo" title="财商少年">
      <img src="~@/assets/img/main/FQLogo.png" alt="财商少年logo">
    </a>
    <nav class="nav">
      <ul class="clearfix">
        <li v-for="router in routerData"
            :key="router.title"
            @mouseover="showChildren(router)"
            @mouseleave="closeChildren(router)"
            class="nav__parents">
          <!--无子项目-->
          <router-link :to="router.href"
                       :title="router.title"
                       v-if="!router.children"
                       class="nav__clickable">
            {{router.title}}
          </router-link>

          <!--有子项目-->
          <span v-if="router.children" class="nav__clickable">{{router.title}}</span>
          <div class="nav__children"
               :style="{height: 74 * router.children.length * (router.isChildrenShow ? 1 : 0) + 'px'}"
               v-if="router.children">
            <div v-for="child in router.children" :key="child.title">
              <router-link :to="child.href" :title="child.title" class="nav__clickable">{{child.title}}</router-link>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
  export default {
    name: "IndexHeader",
    data() {
      return {
        routerData: [
          {
            title: '首页',
            href: '/'
          },
          {
            title: '企业介绍',
            href: '/about'
          },
          {
            title: '产品系统',
            children: [
              {
                title: '产品系统',
                href: '/winner'
              },
              {
                title: '课程系统',
                href: '/curriculum-system'
              },
              {
                title: '专利教具',
                href: '/teaching-aids'
              },
            ],
            isChildrenShow: false
          },
          // 导师团队=导师团队+证书查询
          {
            title: '证书查询',
            href: '/search'
          },
          {
            title: '全国大赛',
            href: '/competition'
          },
          // 萌物品牌
          // 联系我们
        ],
      }
    },
    methods: {
      showChildren(router) {
        router.isChildrenShow = true
      },
      closeChildren(router) {
        router.isChildrenShow = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  a {
    color: inherit;
  }
  // 头部
  .header {
    height: 74px;
    width: 100%;
    min-width: 900px;
    // 两边空出150px
    padding: 0 150px;
    font-size: 18px;
    color: #333;
    position: fixed;
    top: 0;
    z-index: 99;
    background: #ffffff;
    border-bottom: 2px solid #f3f3f3;
  }

  //logo: a=>img
  .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    float: left;

    img {
      width: 120px;
    }
  }

  // 右侧导航: nav=>ul=>li
  .nav {
    float: right;
  }
  .nav__parents {
    position: relative;
    float: left;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }
  .nav__children {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    transition: height 0.5s;
    height: 0;
    overflow: hidden;
  }
  .nav__clickable {
    display: block;
    padding: 0 15px;
    line-height: 74px;
  }
  // 在对应的路由, 导航栏显示蓝色
  .router-link-exact-active {
    color: #00a2e8 !important;
  }

</style>
